import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { css, keyframes } from '@emotion/core'
import isPropValid from '@emotion/is-prop-valid'
import { misc, colors, mixins } from '../../styles/utilities'

const rotate = keyframes`
  0% {
    transform: rotate(0deg) translate(-50%,-50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%,-50%);
  }
`

export const baseButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0;
  position: relative;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  overflow: hidden;
  background: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &:focus,
  &:active,
  &:target {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
  ${mixins.transition()}
`
const getFontSize = ({ size }) => css`
  font-size: ${{
    small: 14,
    medium: 16,
    large: 18,
  }[size] || 14}px;
`
const getPadding = ({ size }) => css`
  padding: ${{
    small: `8px 15px`,
    medium: `11px 30px`,
    large: `18px 65px`,
  }[size] || 0};
`
const getBgColor = ({ variant }) => css`
  background-color: ${{
    blue: colors.btnBlueDefault,
    black: colors.btnBlackDefault,
  }[variant] || `transparent`};
`
const getBgColorOnHover = ({ variant }) => css`
  &:hover {
    background-color: ${{
      blue: colors.btnBlueHover,
      black: colors.btnBlackHover,
    }[variant]};
  }
`
const getBgOnActive = ({ variant }) => css`
  &:active {
    background-color: ${{
      blue: colors.btnBlackActive,
      black: colors.btnBlackActive,
    }[variant]};
  }
`
const getTextColor = ({ variant }) => css`
  &,
  :hover {
    color: ${{
      blue: colors.white,
      black: colors.white,
    }[variant] || 'inherit'};
  }
`
const getBoxShadow = ({ size }) => css`
  box-shadow: ${{
    large: `2px 2px 1px 0 rgba(0, 0, 0, 0.51)`,
  }[size] || `none`};
`
const getLoadingStyles = ({ isLoading, variant }) =>
  isLoading
    ? css`
        pointer-events: none;
        opacity: 0.6;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          ${getBgColor({ variant })};
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform-origin: 0% 0%;
          width: 1.5em;
          height: 1.5em;
          display: block;
          border: 2px solid white;
          border-left-color: transparent;
          border-radius: 50%;
        }
      `
    : ``

const Button = styled('button', {
  shouldForwardProp: prop => isPropValid(prop),
})`
  ${baseButtonStyles};
  ${getFontSize};
  ${getPadding};
  ${getTextColor};
  ${getBoxShadow};
  ${getBgColor};
  ${getBgColorOnHover};
  ${getBgOnActive};
  ${getLoadingStyles};

  ${({ variant }) =>
    variant
      ? `
          border-radius: ${misc.borderRadius.lg};
        `
      : ''};

  font-weight: ${({ weight }) => weight || 'bold'};
  display: ${({ inline }) => (inline ? `inline-flex` : `flex`)};
  ${({ underlineColor }) =>
    underlineColor
      ? css`
          border-bottom: 2px solid ${underlineColor};
          border-radius: 0;
          :hover {
            opacity: 0.7;
          }
        `
      : ``}
  &:after {
    animation: ${rotate} 1s linear infinite;
  }
`
const LinkBtn = Button.withComponent(Link) // for Links the looks like button but are internal

export { LinkBtn }
export default Button
