/* eslint-disable react/prop-types */
import React from 'react'
import { css } from '@emotion/core'

export const CompanyLogo = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='140'
    height='61'
    viewBox='0 0 140 61'
  >
    <defs>
      <path id='a' d='M0 .618h24.835v24.73H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        d='M40.438 46.209s4.202-4.817 4.458-7.824c0 0 3.259 1.376 3.774 2.494.512 1.118 11.488 10.832 11.488 10.832s1.288 5.761-3.086 6.879c-4.289 1.115-5.745-.945-8.487-4.729-2.748-3.782-5.06-4.04-5.06-4.04l-3.087-3.612z'
      />
      <path
        fill='#000'
        d='M59.644 55.063c-.772 1.722-2.916 3.44-4.372 3.44-.343 0-.6-.087-.857-.343l-1.95-1.91 5.048-5.06 2.044 1.984c.432.43.432 1.032.087 1.89zM23.03 23.253l.774-.774c.342-.257.77-.428 1.371-.428 1.458 0 3.429.945 4.975 2.578l24.547 23.827-5.032 5.051-24.49-23.977c-2.572-2.493-3.172-5.244-2.145-6.276zm33.56 27.04l-5.044 5.057-.96-.94 5.034-5.059.97.942zm4.253 1.333l-29.237-28.63c-1.97-1.89-4.458-3.095-6.516-3.095-1.202 0-2.23.429-2.916 1.117l-.77.773c-2.06 2.065-1.117 6.105 2.14 9.286l29.24 28.628c.602.604 1.46.948 2.402.948 2.66 0 5.405-2.408 6.262-4.815.686-1.632.426-3.18-.605-4.212z'
      />
      <path
        fill='#FFF'
        d='M2.536 51.71L18.4 35.978s4.117 9.886 6.428 11.177L11.285 60.652s-5.233-1.03-6.262-3.265c-1.03-2.15-2.916-2.581-2.487-5.676M35.722 18.525l6.857-7.567 5.66-.086.943 6.793 4.634-.945 1.028.43-8.148 8.08-1.112-5.245-.26-1.46z'
      />
      <path
        fill='#FFF'
        d='M43.525 10.958l5.316-.602.341 7.309 6.262-1.72 2.571-5.673-.855-4.729-2.573-1.289-6.861 3.18-3.687 2.493zM12.653 17.064l5.147-5.588 7.115 7.049-6.085.602-.43 3.785c.087 0-4.974-2.583-5.747-5.848'
      />
      <path
        fill='#FFF'
        d='M3.308 4.08s3.345 3.528 2.315 9.286l6.433 2.496 2.571-.861 2.399-1.719-1.37-4.474-2.23-3.352-3.688-.427-4.115-1.55-2.315-.601V4.08z'
      />
      <path
        fill='#000'
        d='M54.498 15.43l-4.713.86.257-5.589c0-.258-.174-.515-.344-.686a1.376 1.376 0 0 0-.771-.257l-3.583.477 1.352-1.339c.472-.354 3.327-1.558 6.901-2.975l3.306 3.233c.007.005.013.007.019.012-.828 2.283-1.716 4.635-2.424 6.264zM14.99 55.092l-1.787-1.775 35.37-35.049c.15.04.317.041.526-.001l3.797-.728-37.906 37.553zm-4.136 4.097c-.512.347-3.087-.171-3.515-.6l-3.344-3.353c-.428-.43-.943-2.922-.6-3.439l2.936-2.909 7.453 7.399-2.93 2.902zm-1.527-9.722L7.538 47.69l35.745-35.413 4.16-.538L9.325 49.467zM47.947 17.61L12.556 52.674 9.97 50.108l38.156-37.847-.23 5.061c0 .099.024.194.05.288zM60.843 1.758a1.181 1.181 0 0 0-.939-.17c-5.06 1.977-13.637 5.244-14.409 6.02l-43.388 42.9c-1.2 1.203-.255 4.988.686 5.931l3.343 3.267c.946.946 4.804 1.892 6.003.689l43.305-42.902c.086-.086.172-.171.172-.258 1.628-3.44 5.315-14.1 5.488-14.53a.974.974 0 0 0-.261-.947z'
      />
      <g transform='translate(.853 -.117)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path
          fill='#000'
          d='M17.898 23.293l-5.489-5.502c1.635-1.581 3.238-3.165 4.871-4.746l5.487 5.502c-1.633 1.583-3.235 3.164-4.869 4.746M9.658 9.185c1.115.086 1.115 1.633 0 1.72-1.116.084-1.116-1.634 0-1.72M5.485 12.9c.31-2.667-.444-5.425-1.643-7.915A248.676 248.676 0 0 0 8.201 9.27c.031.032.066.046.1.066a1.439 1.439 0 0 0-.186.708c-.087.86.77 1.547 1.543 1.547.857.088 1.544-.774 1.544-1.547.078-.802-.659-1.445-1.384-1.53a.25.25 0 0 0-.16-.018.24.24 0 0 0-.184.034 1.888 1.888 0 0 0-.51.174c-.022-.04-.041-.082-.08-.12C7.474 7.309 6.122 5.973 4.78 4.628 7.23 5.69 9.88 6.337 12.422 6.034a716.712 716.712 0 0 0 2.637 6.754c-.67.666-1.359 1.345-2.04 2.037l-.735.717c-2.267-.881-4.533-1.762-6.8-2.641m19.094 5.053c-2.23-2.233-4.46-4.47-6.69-6.706-.265-.267-.57-.295-.83-.19a.82.82 0 0 0-.369.19l-.372.364a666.193 666.193 0 0 0-2.631-6.726c-.17-.43-.514-.689-1.028-.601C8.885 4.973 4.513 2.908 1.254.76.882.499.51.626.278.905c-.241.2-.367.52-.206.851a.8.8 0 0 0 .282.428c2.155 3.07 4.22 7.213 3.473 10.956-.086.515.17.859.598 1.03 2.224.864 4.448 1.717 6.67 2.53-.166.16-.327.32-.494.481a.832.832 0 0 0 0 1.203c2.228 2.237 4.46 4.471 6.688 6.707a.83.83 0 0 0 1.201 0c2.06-1.978 4.029-3.955 6.088-5.934a.832.832 0 0 0 0-1.203'
          mask='url(#b)'
        />
      </g>
      <path
        fill='#FFA520'
        d='M45.324 18.956l-25.381-.431s-4.714 16.851 4.114 27.513c3.518 4.212 4.118 4.3 4.976 4.728 1.201.602 2.06.602 4.288.945l11.92-10.574.083-22.181z'
      />
      <path
        fill='#000'
        d='M19.943 19.556v14.531c0 .085-.172 6.102 4.286 10.832 3.859 4.039 7.204 5.244 8.06 5.418.859-.259 4.204-1.38 8.061-5.418 4.46-4.73 4.29-10.747 4.29-10.832V19.556H19.943zm12.263 33.015h-.171c-.172 0-4.374-.86-9.347-6.106-4.974-5.243-4.888-11.778-4.888-12.378v-16.68h28.896v16.594c0 .515.173 7.136-4.89 12.381-4.972 5.244-9.172 6.103-9.346 6.103l-.254.086z'
      />
      <path
        fill='#000'
        d='M19.943 19.556v14.531c0 .085-.172 6.102 4.286 10.832 3.859 4.039 7.204 5.244 8.06 5.418.859-.259 4.204-1.38 8.061-5.418 4.46-4.73 4.29-10.747 4.29-10.832V19.556H19.943zm12.263 33.015h-.171c-.172 0-4.374-.86-9.347-6.106-4.974-5.243-4.888-11.778-4.888-12.378v-16.68h28.896v16.594c0 .515.173 7.136-4.89 12.381-4.972 5.244-9.172 6.103-9.346 6.103l-.254.086z'
      />
      <path
        fill='#FFF'
        d='M39.235 32.366a2.347 2.347 0 0 1-1.286 1.29c-.427.173-.94.257-1.37.173a13.083 13.083 0 0 0 1.543-4.473c1.113.603 1.63 1.894 1.113 3.01m-6.945 5.248c-1.115-.948-4.883-4.646-5.143-10.577H37.35c-.173 5.931-3.946 9.629-5.061 10.577m3.089 3.179c.343 0 .687.17.858.515h-7.889a.943.943 0 0 1 .858-.515c.684 0 1.283-.26 1.799-.774.429-.431.685-.949.685-1.548.086.088.173.172.259.172.086.085.17.085.34.085.088 0 .17 0 .26-.085 0 0 .085-.084.257-.172a3.2 3.2 0 0 0 .688 1.548 2.652 2.652 0 0 0 1.885.774m-8.833-7.137a2.36 2.36 0 0 1-1.286-1.29c-.515-1.116 0-2.492 1.116-3.01.343 1.721.941 3.183 1.541 4.473-.428.084-.856.084-1.371-.173m11.836-5.329c-.087 0-.087 0 0 0 0-.602.084-1.204.084-1.892 0-.258-.26-.516-.517-.516H26.632c-.256 0-.514.258-.514.516 0 .688 0 1.29.086 1.892h-.086c-1.718.687-2.574 2.75-1.889 4.47.344.859 1.03 1.461 1.802 1.89.43.174.859.26 1.286.26.344 0 .687-.086 1.031-.172a16.702 16.702 0 0 0 1.63 2.237c0 .084.085.17.085.17.256.257.426.688.426 1.117 0 .43-.17.771-.426 1.118-.259.258-.69.428-1.115.428-1.116 0-2.058.948-2.058 2.068 0 .256.257.514.517.514h9.512c.259 0 .519-.258.519-.514 0-1.12-.945-2.068-2.06-2.068-.427 0-.77-.17-1.116-.428-.257-.257-.427-.689-.427-1.118 0-.429.17-.774.427-1.116.086-.087.086-.087.086-.171.514-.604 1.114-1.291 1.63-2.237.343.086.686.172 1.032.172.428 0 .856-.086 1.285-.26.856-.34 1.458-1.031 1.8-1.89.857-1.72 0-3.783-1.713-4.47'
      />
      <text
        fill='#000'
        fontFamily='NeutraDisp-Titling, Neutra Display'
        fontSize='13.831'
        transform='translate(.853 -.314)'
      >
        <tspan x='75' y='25'>
          DAILY
        </tspan>{' '}
        <tspan x='75' y='38.831'>
          QUEST
        </tspan>{' '}
        <tspan x='75' y='52.662'>
          PLUS
        </tspan>
      </text>
    </g>
  </svg>
)

export const FBLogo = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width='24'
    height='24'
  >
    <path d='M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z' />
  </svg>
)

export const TwitterLogo = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width='24'
    height='24'
  >
    <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
  </svg>
)

export const ChevronRight = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 320 512'
  >
    <path d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z' />
  </svg>
)

export const Cross = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <path d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z' />
  </svg>
)

export const Check = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
  >
    <path d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z' />
  </svg>
)

const ShieldOnly = ({ className, shieldColor, id }) => (
  <svg viewBox='0 0 47.069 55.846' width='24' className={className}>
    <defs>
      <path
        id={id}
        fillRule='evenodd'
        d='M3.49 3.414v23.077c0 .135-.28 9.69 6.98 17.201 6.288 6.416 11.735 8.33 13.131 8.606 1.397-.41 6.847-2.19 13.13-8.606 7.264-7.51 6.988-17.066 6.988-17.201V3.414zm19.976 52.432h-.28c-.28 0-7.123-1.368-15.224-9.698C-.14 37.823 0 27.444 0 26.492V0h47.068v26.354c0 .82.283 11.333-7.963 19.663-8.1 8.327-14.94 9.69-15.226 9.69l-.413.138z'
      />
    </defs>
    <path
      fill={shieldColor}
      fillRule='evenodd'
      d='M44.833 2.46L3.49 1.777S-4.19 28.539 10.19 45.47c5.732 6.688 6.709 6.828 8.106 7.508 1.957.956 3.355.956 6.985 1.5l19.417-16.792z'
    />
    <use fillRule='evenodd' xlinkHref={`#${id}`} />
    <use fillRule='evenodd' xlinkHref={`#${id}`} />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M34.915 23.759a3.818 3.733 0 01-2.095 2.047c-.696.276-1.53.409-2.232.276a21.219 20.742 0 002.514-7.105c1.812.96 2.655 3.008 1.812 4.782M23.602 32.09c-1.816-1.505-7.954-7.377-8.377-16.796h16.622c-.283 9.42-6.428 15.29-8.245 16.796m5.032 5.05c.56 0 1.119.271 1.398.819H17.18c.283-.549.838-.82 1.397-.82 1.115 0 2.091-.41 2.931-1.228.7-.685 1.117-1.508 1.117-2.46.14.14.28.275.42.275.14.135.277.135.556.135.143 0 .277 0 .423-.136 0 0 .138-.133.417-.273a5.194 5.078 0 001.122 2.459 4.326 4.229 0 003.07 1.228M14.245 25.806a3.84 3.753 0 01-2.095-2.046c-.839-1.774 0-3.958 1.818-4.782.559 2.734 1.534 5.056 2.511 7.105-.697.133-1.396.133-2.234-.276m19.28-8.463c-.142 0-.142 0 0 0 0-.955.137-1.913.137-3.004 0-.41-.424-.82-.84-.82H14.384c-.417 0-.836.41-.836.82 0 1.091 0 2.049.138 3.005h-.138c-2.799 1.092-4.195 4.367-3.078 7.099.56 1.363 1.68 2.32 2.936 3.001a5.568 5.443 0 002.095.413c.56 0 1.118-.137 1.678-.274.838 1.368 1.816 2.594 2.654 3.553 0 .133.14.27.14.27.418.408.694 1.092.694 1.775 0 .68-.276 1.223-.695 1.775-.42.409-1.121.679-1.814.679-1.819 0-3.353 1.505-3.353 3.284 0 .407.418.817.841.817h15.495c.421 0 .845-.41.845-.817 0-1.779-1.54-3.284-3.355-3.284-.697 0-1.255-.27-1.818-.679-.418-.409-.695-1.094-.695-1.775 0-.683.277-1.23.695-1.774.14-.138.14-.138.14-.271.837-.96 1.814-2.05 2.656-3.554.558.137 1.116.274 1.68.274.697 0 1.394-.137 2.093-.41 1.394-.543 2.374-1.64 2.933-3.003 1.395-2.731 0-6.007-2.792-7.1'
    />
  </svg>
)
export const BlueShieldOnly = props => (
  <ShieldOnly
    {...props}
    shieldColor='#1c76bb'
    id='blue-shield-icon'
  />
)
export const GreenShieldOnly = props => (
  <ShieldOnly
    {...props}
    shieldColor='#4FBA6F'
    id='green-shield-icon'
  />
)
export const PinkShieldOnly = props => (
  <ShieldOnly
    {...props}
    shieldColor='#BE84B6'
    id='pink-shield-icon'
  />
)
export const GreyShieldOnly = props => (
  <ShieldOnly
    {...props}
    shieldColor='#848484'
    id='grey-shield-icon'
  />
)
export const MainShieldOnly = props => (
  <ShieldOnly
    {...props}
    shieldColor='#ffa728'
    id='main-shield-icon'
  />
)
export const DoubleChevronRight = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path d='M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z' />
  </svg>
)
export const Kebab = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...props}
    css={css`
      transform: rotate(90deg);
    `}
  >
    <path d='M6 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z' />
  </svg>
)

const Arrow = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path d='M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z' />
  </svg>
)

export const RightArrow = Arrow
export const LeftArrow = props => (
  <Arrow
    {...props}
    css={css`
      transform: rotateY(180deg);
    `}
  />
)

export const Magnifier = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path d='M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z' />
  </svg>
)

export const EyeOpen = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path d='M14 12c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm10-.449s-4.252 7.449-11.985 7.449c-7.18 0-12.015-7.449-12.015-7.449s4.446-6.551 12.015-6.551c7.694 0 11.985 6.551 11.985 6.551zm-8 .449c0-2.208-1.791-4-4-4-2.208 0-4 1.792-4 4 0 2.209 1.792 4 4 4 2.209 0 4-1.791 4-4z' />
  </svg>
)

export const EyeClose = props => (
  <svg width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path d='M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z' />
  </svg>
)

export const Hamburger = props => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24'>
    <path d='M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z' />
  </svg>
)
export const UserIcon = props => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24'>
    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z' />
  </svg>
)
