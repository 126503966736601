import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { breakpoints } from '../../styles/utilities'

const fontSizesAndLineHeights = {
  xxxxxLarge: {
    sizes: [38, 38, 54, 62, 62],
    lineHeight: [1.17, 1.16, 1.15, 1.25, 1.25],
  },
  xxxxLarge: {
    sizes: [38, 38, 54, 54, 54],
    lineHeight: [1.05, 1.05, 1.05, 1.25, 1.25],
  },
  xxxLarge: {
    sizes: [28, 28, 38, 38, 38],
    lineHeight: [1.34, 1.34, 1.34, 1.34, 1.34],
  },
  xxLarge: {
    sizes: [24, 24, 28, 28, 28],
    lineHeight: [1.05, 1.05, 1.05, 1.05, 1.05],
  },
  xLarge: {
    sizes: [20, 20, 20, 24, 24],
    lineHeight: [1.3, 1.3, 1.3, 1.3],
  },
  large: {
    sizes: [20, 20, 20, 20, 20],
    lineHeight: [1.05, 1.05, 1.05, 1.05, 1.05],
  },
}

const getFontSizeAndLineHeight = props =>
  fontSizesAndLineHeights[props.size]
    ? breakpoints({
        fontSize: fontSizesAndLineHeights[props.size].sizes,
        lineHeight: fontSizesAndLineHeights[props.size].lineHeight,
      })
    : ''

const getColor = props =>
  css`
    color: ${props.color || 'white'};
  `

const getFontWeight = props =>
  css`
    font-weight: ${props.weight || 'normal'};
  `
export default styled.p`
  font-family: 'Loew', '-apple-system', 'BlinkMacSystemFont',
    'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif',
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  text-rendering: optimizeLegibility;
  margin-bottom: 0;
  ${getColor};
  ${getFontWeight};
  ${getFontSizeAndLineHeight};
`
