import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import styledMap from 'styled-map'
import isPropValid from '@emotion/is-prop-valid'
import { colors, spacer } from '../../styles/utilities'

const fontSize = styledMap('size', {
  xLarge: '24px',
  large: '20px',
  medium: '16px',
  small: '14px',
  xSmall: '12px',
  default: '18px',
})

const getColor = ({ color }) =>
  css`
    color: ${color || 'white'};
  `

const getFontWeight = ({ weight }) =>
  css`
    font-weight: ${weight || 'normal'};
  `

const Text = styled('p', {
  shouldForwardProp: prop => isPropValid(prop),
})`
  font-family: 'Muli', '-apple-system', 'BlinkMacSystemFont',
    'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif',
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    sans-serif;
  line-height: 1.4;
  margin-bottom: 0;
  letter-spacing: 0.3px;
  font-size: ${fontSize};
  ${getFontWeight};
  ${getColor};
`
export const ErrorText = props => (
  <Text
    color={colors.red}
    css={spacer.mrT5}
    size='small'
    weight='bold'
    {...props}
  />
)

export const SuccessText = props => (
  <Text
    color={colors.green}
    css={spacer.mrT5}
    size='small'
    weight='bold'
    {...props}
  />
)

Text.propTypes = {
  /**
   * xLarge: '24px',
   * large: '20px',
   * medium: '16px',
   * small: '14px',
   * xSmall: '12px',  */
  size: PropTypes.oneOf([
    'xLarge',
    'large',
    'medium',
    'small',
    'xSmall',
  ]),
  /** Any defined in `colors.js` */
  color: PropTypes.string,
  weight: PropTypes.oneOf(['normal', 'bold']),
}
export default Text
