import React from 'react'
import { css } from '@emotion/core'
import { ExternalLink } from '@dqp/common/components/Link/Link'
import { FBLogo, TwitterLogo } from '@dqp/common/components/icons'
import { spacer } from '@dqp/common/styles/utilities'
import { externalSites } from '#globals/routes'

const styles = {
  icon: css`
    width: 18px;
  `,
}
function SocialMediaLinks() {
  return (
    <div className='mt-0 mt-lg-auto'>
      <ExternalLink
        to={externalSites.facebook}
        target='_blank'
        css={spacer.mrR20}
        aria-label='facebook-link'
      >
        <FBLogo css={styles.icon} />
      </ExternalLink>
      <ExternalLink
        to={externalSites.twitter}
        target='_blank'
        aria-label='twitter-link'
      >
        <TwitterLogo css={styles.icon} />
      </ExternalLink>
    </div>
  )
}

export default SocialMediaLinks
