import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Link as GatsbyLink } from 'gatsby'
import { colors, mixins } from '../../styles/utilities'
import Button from '../Button/Button'

const styles = ({ borderColor, hoverColor }) => css`
  display: inline-block;
  text-decoration: none;
  position: relative;
  border-bottom: 2px solid ${borderColor || 'transparent'};
  color: inherit;
  &:hover {
    text-decoration: none;
    color: ${hoverColor || colors.charcoal};
    fill: ${hoverColor || colors.charcoal};
  }

  ${mixins.transition()};
`
export const ExternalLink = ({
  to,
  className,
  borderColor,
  hoverColor,
  children,
  target,
  activeStyle,
  ...rest
}) => {
  return (
    <a
      href={to}
      css={styles({ hoverColor, borderColor })}
      rel='noopener noreferrer'
      target={target}
      className={className}
      {...rest}
    >
      {children}
    </a>
  )
}

const Link = ({
  to,
  activeStyle,
  className,
  borderColor,
  hoverColor,
  partiallyActive,
  children,
  ...rest
}) => {
  return (
    <GatsbyLink
      to={to}
      activeStyle={activeStyle}
      css={styles({ borderColor, hoverColor })}
      className={className}
      partiallyActive={partiallyActive}
      {...rest}
    >
      {children}
    </GatsbyLink>
  )
}

ExternalLink.propTypes = {
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  borderColor: PropTypes.string,
  hoverColor: PropTypes.string,
  // TODO: this is temporary, since both ExternalLink and Link share same API, activeStyle, a non-standard DOM attribute gets passed.

  activeStyle: PropTypes.object,
}

ExternalLink.defaultProps = {
  target: '_self',
  className: '',
  borderColor: '',
  hoverColor: '',
  activeStyle: {},
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  activeStyle: PropTypes.object,
  borderColor: PropTypes.string,
  hoverColor: PropTypes.string,
  partiallyActive: PropTypes.bool,
}

Link.defaultProps = {
  className: '',
  activeStyle: {},
  borderColor: '',
  hoverColor: '',
  partiallyActive: false,
}

// for Links that looks like button but are EXTERNAL
export const ExternalLinkBtn = Button.withComponent(ExternalLink)
export default Link
